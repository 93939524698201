<template>
  <ValidationProvider v-slot="{ failed, errors }" slim :rules="rules">
    <div
      :class="[
        'question-search',
        { large: isLarge, standalone: isStandalone, haserror: failed },
      ]"
    >
      <slot />

      <input
        :class="{ 'with-magnifier': shouldShowMagnifier }"
        :value="value"
        :placeholder="
          placeholder || $t('questionsPage.input.search.placeholder')
        "
        name="question"
        autocomplete="off"
        @focus="isFocused = true"
        @blur="isFocused = false"
        @input="handleInput($event.target.value)"
        @keydown.enter="handleSearch"
      />

      <MultiselectList
        v-if="similarQuestions.length > 0"
        ref="multiselect"
        class="similar-select"
        :class="{ focused: isFocused }"
        :options="similarQuestions"
        track-by="title"
        :value="value"
        @select="handleSelection"
      >
        <template slot="beforeList">
          <p class="before-title fs18">
            <b
              >{{ $t("questionsPage.input.search.dropdownHeader") }} ({{
                similarQuestions.length
              }})</b
            >
          </p>
        </template>
        <template slot="noOptions">
          <p />
        </template>
        <template slot="noResult">
          <p />
        </template>
        <template slot="option" slot-scope="{ option }">
          <p class="option-title">
            {{ option.title }}
          </p>
          <p class="option-body">
            {{ option.body }}
          </p>
        </template>
      </MultiselectList>

      <JoszakiButton
        v-if="shouldShowMagnifier"
        class="magnifier"
        @click="handleSearch"
      >
        <IconComponent icon="search" />
      </JoszakiButton>

      <p v-if="rules" class="error">{{ errors[0] }} &nbsp;</p>
    </div>
  </ValidationProvider>
</template>

<script>
import SIMILAR_QUESTIONS from "@/graphql/similarQuestions.graphql";

export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    rules: {
      type: String,
      default: null,
    },
    isStandalone: {
      type: Boolean,
      default: false,
    },
    isLarge: {
      type: Boolean,
      default: false,
    },
    shouldShowMagnifier: {
      type: Boolean,
      default: false,
    },
    search: {
      type: Function,
      default: () => {
        throw new Error(
          "If `shouldShowMagnifier` is true, a `search` function must be provided."
        );
      },
    },
  },
  data() {
    return {
      isFocused: false,
      similarQuestions: [],
    };
  },
  methods: {
    findSimilar(value) {
      this.$query(SIMILAR_QUESTIONS, { title: value }).then((res) => {
        if (res.similarQuestions) {
          this.similarQuestions = res.similarQuestions;
        }
      });
    },
    handleInput(value) {
      this.isFocused = true;
      this.$emit("update:value", value);
      this.findSimilar(value);
    },
    handleSearch() {
      this.isFocused = false;
      this.search();
    },
    handleSelection(value) {
      this.$router.push(`/szakivalaszol/${value.seoName}`);
    },
  },
};
</script>

<style lang="scss">
@import "vue-multiselect/dist/vue-multiselect.min.css";
</style>

<style lang="scss" scoped>
.question-search {
  position: relative;
}
input {
  display: block;
  max-width: 100%;
  width: 100%;
  font-size: 16px;
  line-height: 1.5;
  padding: 7px 13px;
  outline: none;
  background: #ffffff;
  border: 1px solid #efefef;
  border-bottom: 2px solid rgba(80, 192, 255, 1);
  border-radius: 3px;

  &::placeholder {
    font-size: 16px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.35);
  }
  &.with-magnifier {
    padding-right: 44px;

    .large & {
      padding-right: 58px;
    }
  }
  .standalone & {
    border-bottom: 1px solid #efefef;
  }
  .large & {
    line-height: 2;
  }
  .haserror & {
    border-bottom-color: red;
  }
}
.magnifier {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 36px;
  height: 36px;

  .large & {
    width: 44px;
    height: 44px;
  }
}
.error {
  color: red;
  margin-top: 8px;
  font-size: 14px;
}
.before-title {
  color: black;
  padding: 13px;
}
p.option-title,
p.option-body {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 8px;
}
p.option-title {
  font-size: 15px;
  font-weight: 600;
}
p.option-body {
  font-size: 12px;
}

// Multiselect styles
.question-search::v-deep {
  .multiselect.similar-select {
    min-height: 0;
    .multiselect__select,
    .multiselect__tags {
      display: none;
    }
    &.focused {
      .multiselect__content-wrapper {
        display: block !important;
        border-color: #efefef;
      }
    }
  }
  .multiselect__content {
    width: 100%;
  }
  .multiselect__tags {
    height: 41px;
    font-size: 16px;
    border: 1px solid #efefef;
    border-bottom: 2px solid rgba(80, 192, 255, 1);
    border-radius: 3px;
  }
  .multiselect__placeholder {
    position: relative;
    bottom: 2px;
    left: 5px;
    color: rgba(0, 0, 0, 0.35);
  }
  .multiselect__select {
    background: url("~assets/img/szakivalasz/dropwdown-arrow.svg") no-repeat
      center;

    &:before {
      border: none;
    }
  }
  .multiselect__option--highlight {
    background: #f3f3f3;
    color: black;

    &.multiselect__option--selected {
      background: #50c0ff;
    }
  }
  .multiselect__option--highlight:after {
    content: "";
    display: none;
  }
  input.multiselect__input {
    font-size: 16px;
  }
  .multiselect__input::placeholder,
  ::placeholder,
  .multiselect__placeholder {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #b7b7b7;
  }
  .multiselect__option,
  .multiselect__single {
    &::first-letter {
      text-transform: capitalize;
    }
  }
}
</style>
