<template>
  <div class="avatar-container">
    <div v-if="image">
      <!-- Fallback -->
      <img
        v-if="isFrameless"
        class="avatar avatar-image frameless"
        v-lazy-load data-src="~/assets/default-avatar.jpg"
        data-not-lazy
      />
      <img v-else v-lazy-load data-src="~/assets/img/szakivalasz/icon.png" data-not-lazy />

      <!-- Photo -->
      <img
        v-if="!error"
        :class="['avatar avatar-image', { frameless: isFrameless }]"
        v-lazy-load :data-src="image"
        @error="error = true"
      />
    </div>
    <div
      v-else-if="text"
      :class="[
        'avatar avatar-text',
        {
          frameless: isFrameless,
          medium: text.length === 3,
          long: text.length > 3,
        },
      ]"
    >
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    isFrameless: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    error: false,
  }),
};
</script>

<style lang="scss" scoped>
.avatar-container {
  display: inline-block;
  position: relative;
  width: 36px;
  height: 36px;
}
.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 2px;
  left: 2px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-weight: bold;
  background: rgba(169, 169, 169, 0.85);
  color: white;

  &.frameless {
    border: 1px solid white;
  }
}
.avatar-image {
  &.frameless {
    border-color: #e2e2e2;
  }
}
.avatar-text {
  font-size: 14px;

  &.medium {
    font-size: 12px;
  }
  &.long {
    font-size: 10px;
  }
}
</style>
