var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"avatar-container"},[(_vm.image)?_c('div',[(_vm.isFrameless)?_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],staticClass:"avatar avatar-image frameless",attrs:{"data-src":require("assets/default-avatar.jpg"),"data-not-lazy":""}}):_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],attrs:{"data-src":require("assets/img/szakivalasz/icon.png"),"data-not-lazy":""}}),(!_vm.error)?_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],class:['avatar avatar-image', { frameless: _vm.isFrameless }],attrs:{"data-src":_vm.image},on:{"error":function($event){_vm.error = true}}}):_vm._e()]):(_vm.text)?_c('div',{class:[
      'avatar avatar-text',
      {
        frameless: _vm.isFrameless,
        medium: _vm.text.length === 3,
        long: _vm.text.length > 3,
      },
    ]},[_vm._v(" "+_vm._s(_vm.text)+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }